/*@font-face {*/
  /*font-family: "PingFang SC";*/
  /*src: url("./assets/fonts/PingFang SC Regular.ttf");*/
/*}*/
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}