.travelling-strength{
    padding: 5em 12% 5em 12%;
    display: flex;
    background: #F6FAFD;
    text-align: center;
}
.travelling-strength>div>p{
    font-weight: bold;
    color: #000000;
    font-size: 1.5em;
}
.travelling-core{
    background: #FFFFFF;
    padding: 5em 12% 5em 12%;
    text-align: center;
}
.travelling-core>div{
    display: flex;
    justify-content: center;
}

.travelling-core-title{
    font-size: 1.5em;
    color: #FFFFFF;
    padding: 1em 2em;
    position: relative;
    bottom: 0;
    font-weight: bold;
}
.travelling-core-desc{
    font-size: 1.2em;
    color: #FFFFFF;
    margin: 0 2em;
    height: 5em;
    padding: 0;
}
.travelling-agent{
    background: #F6FAFD;
    padding: 5em 12% 5em 12%;
    text-align: center;
}